<template>
  <div class="sx-page">
    <sx-card :title="title">
      <div class="sx-page-content">
        <slot></slot>
      </div>
    </sx-card>
  </div>
</template>

<script>
export default {
    name: 'sx-page',
    props: ['title']
}
</script>

<style lang="sass">

.sx-page
  padding: 25px
  margin: 0 auto
  max-width: 1024px

.sx-page-content
    margin-top: 22px
    & > * 
      margin-top: 35px
    & > *:first-child
      margin-top: 0px !important

</style>
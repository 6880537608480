var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sx-page" },
    [
      _c("sx-card", { attrs: { title: _vm.title } }, [
        _c("div", { staticClass: "sx-page-content" }, [_vm._t("default")], 2),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }